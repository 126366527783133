body {
  background-color: #000000;
  color: #ffffff;
  max-width: 800px;
  margin: auto;
  font-family: Noto Sans HK;

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    border: 2px solid white;
    margin-right: 16px;
  }
  
  input[type="radio"]:checked {
    box-shadow: inset 0 0 0 2px black;
    background: #1AB900;
    background-clip: content-box;
  }

  input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    margin-right: 16px;
    background: #333333;
    border: 1.5px solid #333333;
    top: 2px;
    margin: 0px;
    border-radius: 20%;
  }
  
  input[type="checkbox"]:checked {
    width: 6px;
    height: 12px;
    border: solid #1AB900;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    margin: 0px 8px;
  }

  input[type="text"] {
    width: 90%;
    aspect-ratio: 1 / 0.15;
    margin: 17px auto;
    padding: 12px 18px;
    background: rgba(255, 255, 255, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 12px;
    color: #ffffff;
    font-size: 16px;
  }

  input:focus,
  select:focus{
    outline: none;
  }
  
  .logo-blink {
    animation: logo-blink 1s steps(2, start) reverse infinite;
  }
}

@keyframes logo-blink {
	to {
		visibility: hidden;
	}
}


@-webkit-keyframes logo-blink {
	to {
		visibility: hidden;
	}
}