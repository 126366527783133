@import './global-variables.scss';

.pickup-wrapper {
  border: 3px solid $pickupColor;
  box-sizing: border-box;
  backdrop-filter: blur(4px); 
  border-radius: 20px;
  color: $pickupColor;
  margin: 2%;
  .title-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 0;
    .rest-logo{
      margin: 0 2%;
      width: 15%;
      .image{
        width: 100%;
        aspect-ratio: 1 / 1;
      }
    }
    .title{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 70%;
      .pickup-logo{
        margin-right: 5px;
        width: 20%;
        aspect-ratio: 1 / 1;
      }
      .text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        white-space:pre-line;
        .text-zh{
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .pickup-gulu-logo{
      width: 15%;
      position: relative;
      margin: 0 2%;
      .image{
        width: 100%;
        aspect-ratio: 1 / 1;
        position: absolute;
        top: 0;
      }
    }
  }
  .middle-line{
    background: $pickupColor;
    backdrop-filter: blur(4px);
    border-radius: 20px;
    height: 3px;
    margin: 0 2%;
  }
  .pickup-content{
    display: flex;
    flex-wrap: wrap;
    padding: 16px 0px;
    line-height: 30px;
    .text{
      font-weight: 900;
      font-size: 36px;
      font-family: poppins;
      text-align: center;
      margin: 5%;
      &.blink {
        animation: blink-animation 1s 1s linear 4;
        -webkit-animation: blink-animation 1s 1s linear 4;
      }
    }
  }
}

@keyframes blink-animation {
  to {
    color: #ff879d;
  }
}
@-webkit-keyframes blink-animation {
  to {
    color: #ff879d;
  }
}