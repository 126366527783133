$pickupColor: #87FFE9;
$disableColor: #4a4a4a;
$desktopMinWidth: 720px;

$tableTypeAColor: #f85999;
$tableTypeBColor: #6cbb20;
$tableTypeCColor: #07a5f7;
$tableTypeDColor: #e69201;
$tableTypeEColor: #ca61fb;
$tableTypeFColor: #d7d221;

%button {
  width: fit-content;
  padding: 10px;
  cursor: pointer;
}

@mixin display-flex($justify, $align, $direction: row) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin calendar-hover {
  &:hover {
    &:not(.disable) {
      background: none;
      background-color: $hoverColor;
      color: #ffffff;
      .preorder {
        .dot {
          background-color: #ffffff;
          &.empty {
            background-color: transparent;
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }
}